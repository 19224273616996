import { differenceInDays, parse } from "date-fns";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px;
    margin-top: 10px;
    max-width: 800px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
`;

const Title = styled.h1`
    font-size: 1em;
    color: #333;
    text-align: center;
    margin: 4px 0;
`;

const Week = styled.h2`
    font-size: 1em;
    color: #333;
    text-align: center;
    margin: 4px 0;
`;

const Info = styled.div`
    border-bottom: 0.5px solid #6665;
    margin-bottom: 20px;
`;

const Text = styled.p`
    font-size: 1em;
    text-align: center;
    color: #666;
`;

const QuoteTitle = styled.h3`
    font-size: 1em;
    text-align: center;
    color: #444;
    margin-bottom: 0px;
`;

const ScriptureBlock = styled.div`
    margin-top: 10px;
    text-align: center;
`;

const ScriptureReference = styled.h4`
    font-size: 1em;
    color: #555;
    margin-top: 10px;
    margin-bottom: 5px;
`;

const ScriptureText = styled.p`
    font-size: 1em;
    color: #555;

    span.number {
        color: #a60d0d;
        font-weight: bold;
    }
`;

const ToggleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
`;

const ToggleButton = styled.button`
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
    display: block;
    margin: 0 auto 10px auto;
`;

const HighlightNumbers = ({ text }) => {
    const highlightedText = text.replace(
        /\b\d+\b/g,
        (match) => `<span class="number">${match}</span>`
    );

    return <span dangerouslySetInnerHTML={{ __html: highlightedText }} />;
};

const DailyText = ({ texts }) => {
    const [todayText, setTodayText] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        const { title, start, content } =
            texts["11-katolicka-identita-a-ekumenizmus"];
        const startDate = parse(start, "dd.MM.yyyy", new Date());
        startDate.setHours(0, 0, 0, 0);
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const daysDifference = differenceInDays(today, startDate) + 1;
        const currentWeekIndex = Math.floor(daysDifference / 8);
        const currentDayIndex = daysDifference % 7 || 7;

        const currentWeek = content[currentWeekIndex];
        if (
            currentWeek &&
            currentWeek.daysContent[currentDayIndex] &&
            startDate <= today
        ) {
            setTodayText({
                title: title,
                weekTitle: currentWeek.week,
                dayTitle: currentWeek.daysContent[currentDayIndex].title,
                dayText: currentWeek.daysContent[currentDayIndex].text,
                scriptures: currentWeek.daysContent[currentDayIndex].scriptures,
                ...(currentDayIndex === 1 && {
                    infoWeek: {
                        title: currentWeek.daysContent[0].title,
                        text: currentWeek.daysContent[0].text,
                    },
                }),
            });
        } else {
            setTodayText({
                title: "Nie sú k dispozícii žiadne texty pre dnešný deň.",
                scriptures: [],
            });
        }
    }, []);

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    if (!todayText) return null;

    return (
        <Container>
            <ToggleContainer onClick={toggleExpanded}>
                <ToggleButton>☀️</ToggleButton>
                <Title>{todayText.title}</Title>
            </ToggleContainer>

            {todayText?.infoWeek && isExpanded && (
                <Info>
                    <Week>{todayText.infoWeek.title}</Week>
                    <Text>{todayText.infoWeek.text}</Text>
                </Info>
            )}

            {todayText.weekTitle && (
                <Week>
                    {todayText.weekTitle} - {todayText.dayTitle}
                </Week>
            )}

            {isExpanded && (
                <>
                    <Text>{todayText.dayText}</Text>
                    {todayText.scriptures.length > 0 && (
                        <QuoteTitle>Citáty</QuoteTitle>
                    )}
                    {todayText.scriptures.map((scripture, scriptureIndex) => (
                        <ScriptureBlock key={scriptureIndex}>
                            <ScriptureReference>
                                {scripture.reference}
                            </ScriptureReference>
                            <ScriptureText>
                                <HighlightNumbers text={scripture.text} />
                            </ScriptureText>
                        </ScriptureBlock>
                    ))}
                </>
            )}
        </Container>
    );
};

export default DailyText;
