import React from "react";
import styled from "styled-components";
import BackButton from "./BackButton";

// Vytvor styled component pre kontajner
const Container = styled.div`
    padding: 50px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Image = styled.img`
    width: 100%;
    max-width: 800px;
    margin-bottom: 20px;
    border-radius: 10px;

    &.new {
        box-shadow: 0px 0px 20px #ff5722;
    }

    &.old {
        filter: grayscale(60%);
        opacity: 0.5;
    }
`;

const StayTouch = () => {
    return (
        <Container>
            <BackButton to="/" />
            <Image
                className="new"
                src={`${process.env.PUBLIC_URL}/milujatvor.jpg`}
                alt="miluj a tvor"
            />
            <Image
                className="new"
                src={`${process.env.PUBLIC_URL}/papez_varianty.jpg`}
                alt="rim viarianty"
            />
            <Image
                className="new"
                src={`${process.env.PUBLIC_URL}/papez_registracia.jpg`}
                alt="rim registracia"
            />
            <Image
                src={`${process.env.PUBLIC_URL}/stretnutiepapa.jpg`}
                alt="Stretnutie mladých v Ríme"
            />
            <Image
                className="old"
                src={`${process.env.PUBLIC_URL}/dsmo.jpg`}
                alt="DSMO"
            />
            <Image
                className="old"
                src={`${process.env.PUBLIC_URL}/dsmm.jpg`}
                alt="DSMM"
            />
        </Container>
    );
};

export default StayTouch;
