export const generateUUID = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
            const r = (Math.random() * 16) | 0;
            const v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        }
    );
};

export const getOrCreateUserId = () => {
    const userIdKey = "uniqueUserId";
    let userId = localStorage.getItem(userIdKey);

    if (!userId) {
        userId = generateUUID();
        localStorage.setItem(userIdKey, userId);
    }
    return userId;
};
