// src/components/WeekTemplate.js

import React, { useState, useRef } from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f0f0f0;
    padding: 20px;
`;

const Title = styled.h1`
    font-size: 2.5em;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
    margin-top: 10px;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Button = styled.button`
    width: 200px;
    padding: 10px;
    margin: 5px 0;
    font-size: 1.2em;
    cursor: pointer;
    border: none;
    border-radius: 10px;
    background-color: #007bff;
    color: white;
    transition: background-color 0.3s;

    &:hover {
        background-color: #0056b3;
    }
`;

const DayContent = styled.div`
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px;
    margin-top: 10px;
    max-width: 800px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
`;

const Text = styled.p`
    font-size: 1em;
    text-align: center;
    color: #666;
`;

const QuoteTitle = styled.h3`
    font-size: 1.2em;
    text-align: center;
    color: #444;
    margin-bottom: 10px;
`;

const ScriptureBlock = styled.div`
    margin-top: 20px;
    text-align: center;
`;

const ScriptureReference = styled.h4`
    font-size: 1em;
    color: #555;
    margin-top: 10px;
    margin-bottom: 5px;
`;

const ScriptureText = styled.p`
    font-size: 1em;
    color: #555;

    span.number {
        color: #a60d0d;
        font-weight: bold;
    }
`;

const HighlightNumbers = ({ text }) => {
    const highlightedText = text.replace(
        /\b\d+\b/g,
        (match) => `<span class="number">${match}</span>`
    );

    return <span dangerouslySetInnerHTML={{ __html: highlightedText }} />;
};

function WeekTemplate({ title, daysContent }) {
    const [activeDay, setActiveDay] = useState(null);
    const dayRefs = useRef([]); // Reference array for each day's button

    const toggleDay = (day, index) => {
        setActiveDay(activeDay === day ? null : day);

        // Scroll to the specific button's ref
        setTimeout(() => {
            if (dayRefs.current[index]) {
                dayRefs.current[index].scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                });
            }
        }, 100);
    };

    return (
        <Container>
            <Title>{title}</Title>
            {(!daysContent || daysContent.length === 0) && (
                <p>Zatiaľ nie sú pridané texty, buď trpezlivý 😊</p>
            )}
            {daysContent.map(({ day, title, text, scriptures }, index) => (
                <ButtonContainer
                    key={day}
                    ref={(el) => (dayRefs.current[index] = el)} // Attach ref to each button container
                >
                    <Button onClick={() => toggleDay(day, index)}>
                        {title}
                    </Button>
                    {activeDay === day && (
                        <DayContent>
                            <Text>{text}</Text>
                            {scriptures.length > 0 && (
                                <QuoteTitle>Citáty</QuoteTitle>
                            )}
                            {scriptures.map((scripture, scriptureIndex) => (
                                <ScriptureBlock key={scriptureIndex}>
                                    <ScriptureReference>
                                        {scripture.reference}
                                    </ScriptureReference>
                                    <ScriptureText>
                                        <HighlightNumbers
                                            text={scripture.text}
                                        />
                                    </ScriptureText>
                                </ScriptureBlock>
                            ))}
                        </DayContent>
                    )}
                </ButtonContainer>
            ))}
        </Container>
    );
}

export default WeekTemplate;
