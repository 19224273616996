import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const BackButtonContainer = styled.div`
    display: ${({ visible }) => (visible ? "flex" : "none")};
    align-items: center;
    text-decoration: none;
    color: #007bff;
    font-size: 1.2em;
    position: fixed;
    top: 10px;
    left: 10px;
    background-color: white;
    padding: 5px 10px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    opacity: ${({ opacity }) => opacity};
    transition: opacity 0.3s ease, display 0.3s ease;
    cursor: pointer;

    &:hover {
        color: #0056b3;
    }
`;

const ArrowIcon = styled(FaArrowLeft)`
    margin-right: 8px;
`;

const BackButton = ({ to, onClick }) => {
    const [opacity, setOpacity] = useState(1);
    const [visible, setVisible] = useState(true);

    const handleScroll = () => {
        const scrollY = window.scrollY;

        if (scrollY > 0 && scrollY <= 120) {
            setOpacity(1 - scrollY / 120);
            setVisible(true);
        } else if (scrollY > 120) {
            setOpacity(0);
            setVisible(false);
        } else {
            setOpacity(1);
            setVisible(true);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <BackButtonContainer
            as={onClick ? "div" : Link}
            to={onClick ? undefined : to}
            onClick={onClick}
            visible={visible}
            opacity={opacity}
        >
            <ArrowIcon /> Späť
        </BackButtonContainer>
    );
};

export default BackButton;
