// src/pages/FirstYear.js

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import BackButton from "../../Components/BackButton";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f0f0;
    padding: 20px;
`;

const Title = styled.h1`
    font-size: 2.5em;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
`;

const Text = styled.p`
    font-size: 1.2em;
    color: #555;
    text-align: center;
    margin-bottom: 20px;
`;

const StyledLink = styled(Link)`
    font-size: 1.2em;
    color: #007bff;
    text-decoration: none;
    border-bottom: 1px solid #007bff;
    padding: 5px;
    margin-bottom: 20px;

    &:hover {
        color: #0056b3;
        border-color: #0056b3;
    }
`;

const EventContainer = styled.div`
    margin-top: 20px;
    font-size: 1.2em;
    color: #333;
    text-align: center;
`;

const MainTitle = styled.h2`
    font-size: 1.5em;
    color: #333;
    margin-bottom: 10px;
`;

const Subtitle = styled.h3`
    font-size: 1.2em;
    color: #666;
    margin-bottom: 20px;
`;

const SmallText = styled.p`
    font-size: 0.9em;
    color: #555;
    margin-top: 20px;
`;

const Event = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
`;

const EventName = styled.span`
    text-align: start;
    font-weight: bold;
    color: #007bff;
`;

const EventDate = styled.a`
    color: #007bff;
    text-decoration: none;

    &:hover {
        color: #0056b3;
    }
`;

// Funkcia na pridanie jedného dňa ku koncovému dátumu pre Google Kalendár
const addDaysToDate = (date, days) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
};

// Funkcia na generovanie správneho rozsahu dátumov pre Google Kalendár
const getGoogleCalendarDateRange = (dateRange) => {
    const [start, end] = dateRange.split("/").map((date) => new Date(date));

    const startStr = start.toISOString().split("T")[0].replace(/-/g, "");
    const endStr = addDaysToDate(end, 1)
        .toISOString()
        .split("T")[0]
        .replace(/-/g, "");

    return `${startStr}/${endStr}`;
};

// Funkcia na formátovanie dátumu do ľudského formátu na stránke
const formatDateRange = (dateRange) => {
    const [start, end] = dateRange.split("/").map((date) => new Date(date));
    const options = { day: "numeric", month: "numeric", year: "numeric" };

    const startFormatted = start.toLocaleDateString("sk-SK", options);
    const endFormatted = end.toLocaleDateString("sk-SK", {
        day: "numeric",
        month: "numeric",
    });

    return `${startFormatted.split(".")[0]}.-${endFormatted} ${
        startFormatted.split(".")[2]
    }`;
};

function FirstYear() {
    const events = [
        { name: "Božia láska", date: "2024-10-11/2024-10-13" },
        { name: "Ježiš Kristus", date: "2024-11-08/2024-11-10" },
        { name: "Učeníctvo", date: "2024-12-13/2024-12-15" },
        { name: "Vnútorné uzdravenie", date: "2025-01-10/2025-01-12" },
        { name: "Duch Svätý", date: "2025-02-14/2025-02-16" },
        { name: "Okultizmus", date: "2025-03-14/2025-03-16" },
        { name: "Božie slovo", date: "2025-05-02/2025-05-04" },
        { name: "Identita", date: "2025-06-13/2025-06-15" },
        { name: "DESAŤDŇOVKA", date: "2025-07-04/2025-07-13" },
    ];

    const location =
        "Diecézne centrum voľného času Važec, Janka Silana 372, 032 61 Važec, Slovensko";

    return (
        <Container>
            <BackButton to="/" />
            <Title>Prvý ročník</Title>
            <Text>Tento rok si už zvládol, poď pokračovať v druhom! 🥳</Text>
            <StyledLink to="/druhy-rocnik">
                Pokračovať na druhý ročník
            </StyledLink>
            <EventContainer>
                <MainTitle>
                    Pre tých, ktorí si potrebujú niečo nahradiť
                </MainTitle>
                <Subtitle>🗓️ Termíny víkendov DAŠ 1</Subtitle>
                {events.map((event, index) => (
                    <Event key={index}>
                        <EventName>{event.name}</EventName>
                        <EventDate
                            href={`https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
                                `${event.name} - nahrádzanie`
                            )}&dates=${getGoogleCalendarDateRange(
                                event.date
                            )}&location=${encodeURIComponent(
                                location
                            )}&ctz=Europe/Bratislava`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {formatDateRange(event.date)}
                        </EventDate>
                    </Event>
                ))}
                <SmallText>
                    ❗️Je potrebné mať absolvované všetky víkendy prvého
                    ročníka❗️
                </SmallText>
            </EventContainer>
        </Container>
    );
}

export default FirstYear;
