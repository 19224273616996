import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BackButton from "../../Components/BackButton";
import WeekTemplate from "../../Components/WeekTemplate";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: #f0f0f0;
    padding-top: 20px;
`;

const Title = styled.h1`
    font-size: ${(props) => (props.isSmall ? "0.8em" : "2em")};
    margin-bottom: ${(props) => (props.isSmall ? "0" : "20px")};
    margin-top: 30px;
    text-align: center;
    color: #333;
`;

const Button = styled.button`
    width: 200px;
    padding: 10px;
    margin: 10px;
    font-size: 1.5em;
    cursor: pointer;
    border: none;
    border-radius: 10px;
    background-color: #007bff;
    color: white;
    transition: background-color 0.3s;

    &:hover {
        background-color: #0056b3;
    }
`;

function TopicTemplate({ texts }) {
    const [hash, setHash] = useState(window.location.hash.slice(1));

    useEffect(() => {
        const handleHashChange = () => {
            setHash(window.location.hash.slice(1));
        };

        window.addEventListener("hashchange", handleHashChange);

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener("hashchange", handleHashChange);
        };
    }, []);

    const selectedWeek = texts.content.find(
        (week) => week.week.split(".")[0] === hash
    );

    const handleWeekSelection = (week) => {
        window.location.hash = `#${week.week.split(".")[0]}`;
    };

    const handleBackClick = () => {
        window.history.replaceState(null, "", window.location.pathname);
        setHash("");
    };

    return (
        <Container>
            <BackButton
                to={selectedWeek ? "" : "/druhy-rocnik"}
                onClick={selectedWeek ? handleBackClick : null}
            />
            <Title isSmall={selectedWeek}>{texts.title}</Title>
            {selectedWeek ? (
                <WeekTemplate
                    title={selectedWeek.week}
                    daysContent={selectedWeek.daysContent}
                />
            ) : (
                texts.content.map((week, index) => (
                    <Button
                        key={index}
                        onClick={() => handleWeekSelection(week)}
                    >
                        {week.week}
                    </Button>
                ))
            )}
        </Container>
    );
}
export default TopicTemplate;
